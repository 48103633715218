import { useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';

import { CMSFeatureId, CMSFeatureTestConfig, IsCMSFeatureAvailableParams } from 'src/shared/types';
import globalStorageService from 'src/global/services/global-storage-service';
import cmsService from 'src/services/cms-service';
import useProject from './useProject';

const useFeatures = () => {
    const { currentProjectSelectedHabitatAssayType, currentProjectFilters, isChegdProject } = useProject();
    const [featureTestConfig, setFeatureTestConfig] = useState<CMSFeatureTestConfig>(globalStorageService.getFeatureTestConfig() || {});

    useEffect(() => {
        if (featureTestConfig && !isEmpty(featureTestConfig)) {
            return;
        }

        const fetchFeatureTestConfig = async () => {
            const cmsFeatureTestConfig = await cmsService.getFeatureTestConfig();
            globalStorageService.setFeatureTestConfig(cmsFeatureTestConfig);
            setFeatureTestConfig(cmsFeatureTestConfig);
        };
        fetchFeatureTestConfig();
    }, []);

    const isCMSFeatureAvailable = (params: IsCMSFeatureAvailableParams): boolean => {
        const { featureId, habitatId, assayId } = params;
        return featureTestConfig[featureId]?.includes(`${habitatId}-${assayId}`);
    };

    const getCMSFeatureAvailabilityByMetric = (featureId: CMSFeatureId): boolean => {
        return isCMSFeatureAvailable({
            featureId,
            habitatId: currentProjectSelectedHabitatAssayType?.habitat.habitatKey || '*',
            assayId: currentProjectSelectedHabitatAssayType?.assay.assayId || '*',
        });
    };

    const shouldShowFeatureContent = (featureId: CMSFeatureId) => {
        return (
            currentProjectFilters?.habitatAssays.some(entry => {
                return isCMSFeatureAvailable({
                    featureId: featureId,
                    habitatId: entry.habitat.habitatKey,
                    assayId: entry.assay.assayId,
                });
            }) || false
        );
    };

    return {
        isCMSFeatureAvailable,
        hasSpeciesRichnessFeature: getCMSFeatureAvailabilityByMetric(CMSFeatureId.SPECIES_RICHNESS),
        hasEvolutionaryDiversityFeature: getCMSFeatureAvailabilityByMetric(CMSFeatureId.EVOLUTIONARY_DIVERSITY),
        hasCommunityComparisonFeature: getCMSFeatureAvailabilityByMetric(CMSFeatureId.COMMUNITY_COMPARISON),
        hasFishMetricsFeature: getCMSFeatureAvailabilityByMetric(CMSFeatureId.FISH_METRICS),
        hasInvasivesFeature: getCMSFeatureAvailabilityByMetric(CMSFeatureId.INVASIVES),
        hasIucnRedListFeature: getCMSFeatureAvailabilityByMetric(CMSFeatureId.IUCN_RED_LIST),
        hasFfdFeature: getCMSFeatureAvailabilityByMetric(CMSFeatureId.FUNGAL_FUNCTIONAL_DIVERSITY),
        hasBfdFeature: getCMSFeatureAvailabilityByMetric(CMSFeatureId.BACTERIAL_FUNCTIONAL_DIVERSITY),
        hasSpeciesHighlightFeature: getCMSFeatureAvailabilityByMetric(CMSFeatureId.SPECIES_HIGHLIGHT),
        shouldShowInvasivesContent: shouldShowFeatureContent(CMSFeatureId.INVASIVES),
        shouldShowIucnRedlistContent: shouldShowFeatureContent(CMSFeatureId.IUCN_RED_LIST),
        hasChegdFeature: isChegdProject,
    };
};

export default useFeatures;

import React from 'react';
import { TaxonomySummary } from 'src/shared/types/graph/project';
import QuestionButtonHelp from 'src/shared/components/question-button-help/QuestionButtonHelp';
import Table from 'src/shared/components/table/Table';
import styles from './ChegdTable.module.scss';

type ChegdTableProps = {
    data: TaxonomySummary;
};

const ChegdTable = (props: ChegdTableProps) => {
    const { data } = props;

    return (
        <div className={styles.chegdTable}>
            <div className={styles.top}>
                <div className={styles.title}>CHEGD species</div>
                <span className={styles.questionButton}>
                    <QuestionButtonHelp
                        type='api'
                        slug='chegd-species-helptext'
                        detailedExplainerSlug={`chegd-species-helptext-te`}
                        showHelpTitle={false}
                    />
                </span>
            </div>
            <div>
                <Table>
                    <thead>
                        <tr>
                            <th>Scientific Name</th>
                            <th>Fungal group</th>
                            <th>Fungal group common name</th>
                            <th>Number of Samples</th>
                            <th>% of samples</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td>{item.taxonomy.tax_species || 'Unassigned'}</td>
                                    <td>{item.conservationStatus.chegdGroup}</td>
                                    <td>{item.conservationStatus.chegdCommonName}</td>
                                    <td>{item.sampleCount}</td>
                                    <td>{item.percentTotalSamples}</td>
                                </tr>
                            );
                        })}

                        {data.length === 0 && (
                            <>
                                <tr></tr>
                                <tr>
                                    <td colSpan={5}>There were no CHEGD species detected in this dataset</td>
                                </tr>
                            </>
                        )}
                    </tbody>
                </Table>
            </div>
        </div>
    );
};

export default ChegdTable;

import { ConservationStatus } from '../types/graph/project';

export enum IUCNStatus {
    LEAST_CONCERN = 'Least Concern',
    NEAR_THREATENED = 'Near Threatened',
    VULNERABLE = 'Vulnerable',
    ENDANGERED = 'Endangered',
    CRITICALLY_ENDANGERED = 'Critically Endangered',
    EXTINCT_IN_THE_WILD = 'Extinct in the Wild',
    EXTINCT = 'Extinct',
    DATA_DEFICIENT = 'Data Deficient',
    NOT_EVALUATED = 'Not Evaluated',
    NOT_FOUND = 'Not Found',
    INVASIVE = 'Invasive',
}

export const iucnStatusData: { [key: string]: { label: string; rank: number; code: string } } = {
    CR: { label: 'Critically Endangered', rank: 1, code: 'CR' },
    EN: { label: 'Endangered', rank: 2, code: 'EN' },
    VU: { label: 'Vulnerable', rank: 3, code: 'VU' },
    IN: { label: 'Invasive', rank: 4, code: 'INV' },
    NT: { label: 'Near Threatened', rank: 5, code: 'NT' },
    LC: { label: 'Least Concern', rank: 6, code: 'LC' },
    NE: { label: 'Not Evaluated', rank: 7, code: 'NE' },
    DD: { label: 'Data Deficient', rank: 8, code: 'DD' },
    EX: { label: 'Extinct', rank: 9, code: 'EX' },
    EW: { label: 'Extinct in the Wild', rank: 10, code: 'EW' },
    NF: { label: 'Not Found', rank: 11, code: 'NF' },
};

export const getIucnCodeFromLabel = (label: string): string | undefined => {
    for (const code in iucnStatusData) {
        if (iucnStatusData[code].label === label) {
            return code;
        }
    }
    return undefined;
};

export const getIucnCodeFromStatus = (status: string) => {
    return Object.values(iucnStatusData).find(entry => entry.label === status)?.code;
};

export const getConservationStatusTag = (conservationStatus: Partial<ConservationStatus>) => {
    const iucnStatus = conservationStatus?.iucnRedListStatus || '';
    const invasiveStatus = conservationStatus?.onGRIISList || false;
    const chegdGroup = conservationStatus?.chegdGroup || '';

    if (
        iucnStatus &&
        [iucnStatusData.CR.code, iucnStatusData.EN.code, iucnStatusData.VU.code, iucnStatusData.NT.code].includes(iucnStatus)
    ) {
        return {
            iucnStatusLabel: iucnStatusData[iucnStatus].label,
            iucnStatusRank: iucnStatusData[iucnStatus].rank,
            iucnStatusType: 'redList',
            isInvasive: invasiveStatus,
            chegdGroup,
        };
    } else if (iucnStatus) {
        return {
            iucnStatusLabel: iucnStatusData[iucnStatus].label,
            iucnStatusRank: iucnStatusData[iucnStatus].rank,
            iucnStatusType: 'default',
            isInvasive: invasiveStatus,
            chegdGroup,
        };
    } else {
        return { iucnStatusLabel: '', iucnStatusRank: 20, iucnStatusType: '', isInvasive: invasiveStatus, chegdGroup };
    }
};
